* {
  font-family: Helvetica;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-y: hidden;
}

.active {
  background-color: #0b5ed7 !important;
  border: 0.5px solid #0b5ed7 !important;
  color: white !important;
}

.textArea > div > b {
  font-weight: bolder !important;
  font-size: larger;
}
.admin-section {
  overflow-y: scroll;
}
.errorField {
  position: absolute;
  left: 39%;
  top: 25%;
}
.header img {
  /* height: 70px !important; */
  width: 100px;
}
.verifiedImage {
  max-height: 50vh;
}
.keysArea {
  max-height: 300px !important;
  overflow-y: scroll !important;
}

/* .verifyImg {
  width: 70px !important;
} */
.authenticText {
  font-size: 18px;
}
.verifiedProduct {
  height: 250px !important;
}
.products img {
  width: auto !important;
}

.verifyButton {
  background-color: #f9b656;
  border: none;
  font-size: 15px;
  letter-spacing: -1px;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.anotherbutton {
  background-color: #f9b656;
  width: 300px !important;
  border: none;
  font-size: 12px;
  letter-spacing: -1px;
  text-transform: uppercase;
}
.branding {
  color: #f9b656;
  font-size: 18px !important;
  letter-spacing: -1px !important;
  line-height: 20px !important;
}
.astroExtract {
  font-size: 10px !important;
  letter-spacing: -1px;
}

#verificationCode {
  width: 25% !important;
  background-color: aliceblue;
  border-radius: 20px !important;
}
.errorText {
  font-size: 20px;
}

.footerImg {
  width: 80px;
}
.footer {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-size: 12px !important;
}

.textArea > div {
  color: black;
  font-size: 14px !important;
}
.footerText {
  font-weight: bolder;
  letter-spacing: -1px;
}

.errorImage {
  width: 100px !important;
}

.errorAnotherButton {
  padding: 20px !important;
  margin-top: 50px !important;
}

.productImages {
  max-width: 100%;
}
@media (min-width: 2200px) {
  .verifyImg {
    width: 150px !important;
  }
  .authenticText {
    font-size: 30px !important;
  }
  .verifiedProduct {
    height: 500px !important;
  }
  .textArea div {
    font-size: 25px !important;
  }
  .anotherbutton {
    font-size: 30px !important;
    width: 500px !important;
  }
  .errorImage {
    width: 300px !important;
  }
  .errorText {
    font-size: 40px !important;
  }
  .errorAnotherButton {
    font-size: 30px !important;
    width: 500px !important;
  }
}
@media (max-width: 2200px) {
  .verifyImg {
    width: 100px !important;
  }
  .textArea div {
    font-size: 16px !important;
  }
}
@media (max-width: 1024px) {
  .verifyImg {
    width: 80px !important;
  }
  .authenticText {
    font-size: 20px !important;
  }
  .verifiedProduct {
    height: 220px !important;
  }
  .textArea div {
    font-size: 12px !important;
  }
  .anotherbutton {
    font-size: 10px !important;
  }
}
@media (max-width: 992px) {
  #verificationCode {
    width: 30% !important;
  }
}

@media (max-width: 767px) {
  .verifyArea {
    background-image: none !important;
    background-color: white !important;
  }
  .loginArea {
    background-image: none !important;
    background-color: white !important;
  }
  .verifyImg {
    width: 50px !important;
  }
  .verifiedProduct {
    width: 100px !important;
    height: 400px !important;
  }
  .textArea {
    font-size: 15px;
  }
  .errorArea {
    background-image: none;
    background-color: white;
  }
  #verificationCode {
    width: 50% !important;
  }
  .top-heading {
    font-size: 15px !important;
  }
  .productImages {
    max-width: none;
  }
}
@media (max-width: 628px) {
  /* .toolArea {
    background-image: none;
    background-color: white;
    min-height: 100vh !important;
  } */
  .verifyArea {
    width: 100vw !important;
  }
  .verifiedProduct {
    width: 100px !important;
    height: 350px !important;
  }
  .textArea {
    font-size: 15px;
  }
}

@media (max-width: 575px) {
  .toolArea-1 {
    height: 80vh;
    display: flex;
  }
  .errorArea {
    margin-top: -30px !important;
  }
  .errorField {
    left: 17%;
    top: 30%;
  }

  .verifiedAreaMain {
    width: 90% !important;
  }
  .errorText {
    font-size: 23px;
  }
  .top-heading {
    font-size: 25px !important;
  }
  /* .verify-btn {
    padding-top: 10px;
  } */
  #verificationCode {
    width: 70% !important;
  }
  .verifiedProduct {
    height: 350px !important;
  }
  .textArea div {
    font-size: 10px !important;
  }
  .products img {
    padding-top: 40px;
    max-height: 12em !important;
  }
  /* .errorImage {
    width: 200px !important;
  } */
  .verifyImg {
    width: 110px !important;
  }
  .textArea > div {
    color: black;
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 420px) {
  .toolArea-1 {
    height: 80vh;
    display: flex;
  }
  .verifyImg {
    width: 60px !important;
  }
  .verifiedProduct {
    height: 300px !important;
  }

  .products {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .products img {
    padding-top: 10px;
    max-height: 10em !important;
  }

  .footerImg {
    width: 50px;
  }
}

@media (max-width: 319px) {
  .verifiedProduct {
    width: 100px !important;
    height: 200px !important;
  }
  .textArea {
    font-size: 9px;
  }
}

/* @media only screen and (max-device-width: 428px) and (max-device-height: 926px) and (orientation: portrait) {
  .verifyImg {
    width: 120px !important;
  }
  .verifiedProduct {
    height: 400px !important;
  }
  .textArea div {
    font-size: 15px !important;
  }
} */

/* @media (max-height: 845px) {
  .toolArea {
    overflow-y: hidden !important;
  }
  .verifyArea {
    padding-top: 10px !important;
    overflow-y: hidden !important;
  }
  .top-heading {
    font-size: 30px !important;
  }
  .verificationText > label {
    padding-top: 5vh !important;
  }

  #verificationCode {
    padding: 15px;
  }
  .verifyImg {
    width: 100px !important;
  }
  .textArea > div {
    color: black;
    font-size: 15px !important;
  }
  
} */

/* @media (max-height: 750px) {
  .verifyArea {
    padding-top: 15px !important;
  }
  .top-heading {
    font-size: 17px;
  }
  .verificationText {
    font-size: 13px;
  }
  #verificationCode {
    padding: 5px;
    margin-top: -10px;
  }
  .verify-btn {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
  .productArea {
    margin-top: -10px;
    padding-top: 0px !important;
    margin-bottom: -30px;
  }
  .branding {
    font-size: 14px;
  }
} */

/* @media (max-height: 667px) {
  .verifyArea {
    padding-top: 20px;
  }

  #verificationCode {
    padding: 10px !important;
    margin-top: 10px;
  }
  .verify-btn {
    width: 150px;
    height: 50px;
    font-size: 15px;
    margin-top: 10px;
  }
  .productArea {
    padding: 10px;
    margin-top: 0px !important;
    margin-bottom: -30px;
  }
  .branding {
    font-size: 14px;
  }
} */

/* @media (min-height: 56px) and (max-height: 600px) {
  .verifyArea {
    margin-top: 20px;
  }
  .top-heading {
    font-size: 16px !important;
  }

  .branding {
    font-size: 12px !important;
  }
} */

@media (max-height: 570px) {
  body {
    overflow-y: scroll !important;
  }
}
